.app-container {
  text-align: center;
}

.app-header {
  padding: 20px;
  background-color: #030303; /* Adjust color as needed */
}

.logo {
  max-width: 150px; /* Adjust size as needed */
}

.app-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 60vh; /* Adjust as needed */
}

.app-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px;
  background-color: #030303; /* Adjust background color as needed */
  color: white; /* Sets the text color to white */
}
